<!-- //组织架构 -->
<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-12 col-xxl-12 mt-80-mb-140">
        <img :src="imgRef" class="img-fluid mx-auto d-block" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { getOrganization } from '@/server/intoHuadu'
import { ref, onMounted } from 'vue'
const imgRef = ref('')
onMounted(async () => {
  const r = await getOrganization()
  imgRef.value = r
})
</script>
<style lang="stylus" scoped></style>
